<template>
  <div class="page-content">
    <div class="container-fluid">

      <Breadcrumb
          :pgTitle="$t('nav.faqs')"
          :pgIcon="'bx bx-comment'"
          :refs="refs"
          pgCurrent="Edit"
          :addNew="false">
      </Breadcrumb>
      <!-- Main -->
      <div class="row">

        <Loading
            v-if="pgLoading">
        </Loading>

        <div v-if="!pgLoading" class="col-12">
          <div class="d-flex">

            <!-- Card -->
            <div class="col-12">
              <div class="row">
                <div class="col-6">
                  <h2 class="card-title text-left title" :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">{{ $t('app.question') }}: </h2>
                </div>
                <div class="col-6">
                  <h2 class="card-title text-right title" :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">{{ row.date }}
                  </h2>
                </div>
              </div>
              <div class="card">
                <div class="card-body p-4">
                  <h2 :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">{{ row.question }}</h2>
                </div>
              </div>
              <!-- End Card -->
            </div>

          </div>


          <div class="col-12">
            <div class="row">
              <div class="col-6">
                <h2 class="card-title text-left title " :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">{{ $t('app.answer') }}: </h2>
              </div>
            </div>
            <div class="card">
              <div class="card-body p-4">
                <h2 :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">{{ row.answer }}</h2>
              </div>
            </div>
          </div>
          <!-- End Card -->
        </div>


      </div>

    </div>
    <!-- End Main -->

  </div>
</template>

<script>
import iziToast from 'izitoast';

export default {
  name: 'Show',
  components: {
    Breadcrumb: () => import('@/components/Breadcrumb.vue'),
    Loading: () => import('@/components/Loading.vue'),
  },
  data() {
    return {
      auth: {
        role: '',
        access_token: '',
      },

      refs: "faqs",
      row: {

        answer: '',
        question: '',
        date: '',
      },
      pgLoading: false,
    }
  },
  created() {
    if (localStorage.getItem('access_token')) {
      this.auth.access_token = localStorage.getItem('access_token');
    }
    if (localStorage.getItem('role')) {
      this.auth.role = localStorage.getItem('role');
    }

    if (this.$route.params.id) {
      this.fetchRow();
    }
  },
  methods: {
    //
    // fetch Row
    fetchRow() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
        'Authorization': `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + '/' + this.refs + '/' + this.$route.params.id,
        method: 'GET',
        data: {}
      }
      this.axios(options)
          .then(res => {
            this.pgLoading = false;

            this.row.answer = res.data.row.answer;
            this.row.date = res.data.row.date;
            this.row.question = res.data.row.question;

            this.row.status = res.data.row.status;
          })
          .catch(err => {

            // 403 Forbidden
            if (err.response && err.response.status == 401) {
              this.clearLocalStorage();
              this.$router.push({name: 'login'});
            } else if (err.response && err.response.status == 403) {
              this.$router.push({name: 'forbidden'});
            } else {
              this.btnLoading = false;
              iziToast.warning({
                icon: 'bx bx-angry',
                title: '',
                message: (err.response) ? err.response.data.message : '' + err
              });
            }

          })
          .finally(() => {
          });
    },

  },
}
</script>
<style scoped>
h2{
  font-size: 1.9rem;
}

.title{
  font-size: 1rem;
}

</style>